import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom';

const ItVendor = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [lastname, setLastName] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.lastname = ""; errors.massage = "";

            var url = "https://wa.me/7410153100?text="
                + " Name : " + name + " Email : " + email + " Phone : " + number + " Last Name : " + lastname + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setLastName("");
        setMassage("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!lastname) {
            errors.lastname = "Last Name Is Requried";
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Comments Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services5.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    IT Vendor, Warranty & Hardware Management
                                </h2>
                                <p>
                                    We understand the technology-latent issues that keep you up at night.
                                    You may have questions such as, "How will I grow my business while
                                    minimizing expenses?", or, "How will I protect my data from situations like
                                    natural disasters and all the malicious entities out there?"
                                </p>
                                <p>
                                    You aren't alone.
                                </p>
                                <p>
                                    Many business owners and executives at small and midsized
                                    businesses are tearing their hair out trying to come up with
                                    solutions for some of the most frustrating and time-consuming issues their
                                    company faces. At ITSC, we deal in solutions!
                                </p>
                                <p>
                                    What if we told you that we could supply you with consulting
                                    services, just like a Chief Information Officer, at a fraction
                                    of the cost? The IT professionals at ITSC can do just that.
                                    With our Virtual CIO service, we provide you with an IT roadmap aligned
                                    specifically to make your business more profitable.
                                </p>
                                <p>
                                    Our vendor management service works with third-party vendors
                                    to help you reduce bottom-line expenses and present you solutions
                                    on the determination of which hardware and software suppliers will
                                    work best to suit your needs. Our service ranges from a simple
                                    consultation to the more in-depth program of infrastructure management.
                                    In this way, our Virtual CIO offering is sure to be a valuable asset to rely on when it’s
                                    time to evaluate your company’s technology needs.
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Increase Your Company's Efficiency </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> IT Vendor, Warranty & Hardware Management </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Augment Your Current IT Department with ITSC's IT Professionals </b></span>
                                </p><p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Reliability and Accessibility </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Improve Your Business Operations </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Cost-Efficient and Predictable </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Get the Support You Need with Co-Managed IT </b></span>
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Increase Your Company's Efficiency
                                </h2>
                                <h3>
                                    Let us be your go-to solution.
                                </h3>
                                <p>
                                    We all have that one person in the office that people turn to
                                    whenever they have a problem with their technology. You may
                                    even be witnessing a decline in performance from them because of this.
                                    Those people who become the office "hero" often get burnt out quickly
                                    and see their performance diminish because they
                                    spend most of their time arguing on the phone with vendors.
                                </p>
                                <p>
                                    You need a central point of contact that can handle all these
                                    issues and solve them effectively and efficiently. The IT professionals
                                    at ITSC are proud to provide this service as a part
                                    of our flat-rate managed services contract. Having us aboard can
                                    free you and your office hero up so that you can focus on the administration
                                    of your business. It also helps that we're fluent in technical jargon,
                                    so vendors are less likely to point fingers because we can
                                    literally tell them the solution they need to implement.
                                </p>
                                <p>
                                    With our Vendor Management service, we take a physical inventory of all of
                                    your hardware and software along with serial numbers, warranties, registrations,
                                    licenses, and configurations, keeping them documented for easy access. This helps
                                    limit downtime when something breaks or is damaged. Anytime you need a new piece
                                    of hardware or software; or are simply having a problem with a particular part of your IT,
                                    all you have to do is call us. Since we have this information on hand, we will take
                                    the time to handle the relationship with the necessary vendors on your behalf. When we’re done,
                                    we will inform you of what was done or is expected to be done in real time.
                                </p>
                                <p>
                                    While your IT is essential to your business, it can be a headache
                                    that you don't need. Please take a minute to browse our website for
                                    more information on our valuable managed services. Feel free to
                                    contact us at
                                    <NavLink className="text-decoration-none" to='tel:917410153100'>
                                        <span className='ms-2 me-1' style={{ color: "#01548d", fontWeight: "500" }}>+ 91 7410153100</span>
                                    </NavLink>
                                    or simply fill in
                                    the form to have one of our professional technicians contact you.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Services
                                </h2>
                                <NavLink className="text-decoration-none" to='/itconsulting'>
                                    <p>
                                        IT Consulting
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/manageditservices'>
                                    <p>
                                        Managed Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/comprehensive'>
                                    <p>
                                        Comprehensive IT Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/remote'>
                                    <p>
                                        Remote Monitoring & Maintenance
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/itvendor'>
                                    <p>
                                        IT Vendor Management
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/infrastructure'>
                                    <p>
                                        Infrastructure Support Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/professional'>
                                    <p>
                                        Professional Server Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/staffingservices'>
                                    <p>
                                        Staffing Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/helpdesk'>
                                    <p>
                                        24/7 Help Desk
                                    </p>
                                </NavLink>
                            </div>

                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Questions?
                                </h2>
                                <p>
                                    Do you have questions about IT Services and what they can do for your company? Fill out this simple form with your questions and our team respond promptly!
                                </p>
                                <p>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-12 ">
                                            <input type="text" placeholder='Name' className="form-control text-dark" style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Last Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setLastName(e.target.value)} value={lastname}
                                            />
                                            {errors.lastname && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.lastname}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="email" placeholder='Email' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Phone' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className="col-lg-12">
                                            <textarea type="text" placeholder='Comments' className="form-control" style={{ borderRadius: "2px", height: "100px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button type="submit" className="  FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Conatct Us </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ItVendor