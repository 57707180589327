import React from 'react'
import { Routes, Route } from 'react-router-dom'
import "./Common.css"
import Home from './Home'
import Navbar from './Navbar'
import Footer from './Footer'
import About from './About'
import Services from './Services'
import ManagedITServices from './ManagedITServices'
import Error from './Error'
import Comprehensive from './Comprehensive'
import ItConsulting from './ItConsulting'
import Remote from './Remote'
import ItVendor from './ItVendor'
import HelpDesk from './HelpDesk'
import Professional from './Professional'
import Infrastructure from './Infrastructure'
import ContactUs from './ContactUs'
import StaffingServices from './StaffingServices'
import NetworkingAndInfrastructure from './NetworkingAndInfrastructure'
import DataManagementAndStorage from './DataManagementAndStorage'
import BusinessProcessAutomation from './BusinessProcessAutomation'
import CyberSecuritySolutions from './CyberSecuritySolutions'
import Erp from './Erp'
import Crm from './Crm'
import ProjectManagementSolutions from './ProjectManagementSolutions'
import Bcdr from './Bcdr'
import WebsiteAndEcommerce from './WebsiteAndEcommerce'
import Login from './Login'
import CustomerLogin from './CustomerLogin'
import Hardware from './Hardware'
import Education from './Education'
import ProfessionalIndustries from './ProfessionalIndustries'
import Financial from './Financial'
import Manufacturing from './Manufacturing'
import Growing from './Growing'
import Technology from './Technology'
import Insurance from './Insurance'
import NonProfit from './NonProfit'

const Routing = () => {
    return (
        <>

            <React.Fragment>
                <header>
                    <Navbar />
                </header>

                <main className='MainMargin'>
                    <Routes>
                        <Route exact to path='/' element={<Home />} />
                        <Route exact to path='/about' element={<About />} />
                        <Route exact to path='/services' element={<Services />} />
                        <Route exact to path='/manageditservices' element={<ManagedITServices />} />
                        <Route exact to path='/itconsulting' element={<ItConsulting />} />
                        <Route exact to path='/comprehensive' element={<Comprehensive />} />
                        <Route exact to path='/remote' element={<Remote />} />
                        <Route exact to path='/itvendor' element={<ItVendor />} />
                        <Route exact to path='/helpdesk' element={<HelpDesk />} />
                        <Route exact to path='/infrastructure' element={<Infrastructure />} />
                        <Route exact to path='/professional' element={<Professional />} />
                        <Route exact to path='/staffingservices' element={<StaffingServices />} />

                        <Route exact to path='/networkingandinfrastructure' element={<NetworkingAndInfrastructure />} />
                        <Route exact to path='/dmas' element={<DataManagementAndStorage />} />
                        <Route exact to path='/bpam' element={<BusinessProcessAutomation />} />
                        <Route exact to path='/css' element={<CyberSecuritySolutions />} />
                        <Route exact to path='/erp' element={<Erp />} />
                        <Route exact to path='/crm' element={<Crm />} />
                        <Route exact to path='/projectmanagementsolutions' element={<ProjectManagementSolutions />} />
                        <Route exact to path='/bcdr' element={<Bcdr />} />
                        <Route exact to path='/wecomm' element={<WebsiteAndEcommerce />} />

                        <Route exact to path='/education' element={<Education />} />
                        <Route exact to path='/professionalindustries' element={<ProfessionalIndustries />} />
                        <Route exact to path='/financial' element={<Financial />} />
                        <Route exact to path='/manufacturing' element={<Manufacturing />} />
                        <Route exact to path='/growing' element={<Growing />} />
                        <Route exact to path='/technology' element={<Technology />} />
                        <Route exact to path='/insurance' element={<Insurance />} />
                        <Route exact to path='/nonprofit' element={<NonProfit />} />

                        <Route exact to path='/hardware' element={<Hardware />} />

                        <Route exact to path='/contact' element={<ContactUs />} />

                        <Route exact to path='/login' element={<Login />} />
                        <Route exact to path='/customerlogin' element={<CustomerLogin />} />

                        <Route exact to path='*' element={<Error />} />
                    </Routes>
                </main>
                <footer>
                    <Footer />
                </footer>
            </React.Fragment>

        </>
    )
}

export default Routing