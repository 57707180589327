import React from 'react'

const Technology = () => {
  return (
    <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-lg p-lg-3">
                    <div className="row p-3">
                        <div className='IndustriesMain'>
                            <h2>
                                Our Expertise
                            </h2>
                            <h5>
                                Expertise in Technology
                            </h5>
                            <p>
                                Improving customer-centricity, creating differentiation, building operational
                                efficiency and cost optimization in the Healthcare industry are urgent needs.
                                To aid in meeting these goals, Itsc provides clients with services and customized
                                solutions for prudent financial management, business performance management,
                                continuous operational improvement, responsiveness to customer needs and processes
                                to attract and retain top talent. We do this using next-gen business platforms,
                                SLA-driven IT service delivery, risk-assessment, etc. Among the demonstrated outcomes
                                are performance improvement,
                                lowered cost of operations, reduced time to market and enhanced customer experience.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#e8e8e8" }}>
                    <div className="container-lg  CommanIndustriesStories">
                        <h2 className='CommanIndustries'>
                            What We Think
                        </h2>
                        <div className="row  g-4">
                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Finicial1.png")} alt="" />
                                            <div className='content'>
                                                <h2> Rightshoring IT: Why Geo-Diversification Is Essential To Enterprise Outsourcing </h2>
                                                <p>
                                                    The value stream for outsourced business and IT services
                                                    has grown dramatically over the past 20 years.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Finicial2.png")} alt="" />
                                            <div className='content'>
                                                <h2> Designing a commercial model to unlock revenue potential </h2>
                                                <p>
                                                    With matured outsourcing and the rise in customers’ expectations,
                                                    the service providers are moving towards emerging...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Finicial3.png")} alt="" />
                                            <div className='content'>
                                                <h2> Changing the face of Itsc’s Australian workforce </h2>
                                                <p>
                                                    Around the world, workforce management has evolved with time
                                                    and Australia remains no exception to this.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Finicial4.png")} alt="" />
                                            <div className='content'>
                                                <h2> There is More Than One Cost at Stake When It Comes to IT Outsourcing </h2>
                                                <p>
                                                    Since the dawn of the outsourcing era, the need for organizations
                                                    to leverage a global knowledge base and access fresh...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
  )
}

export default Technology