import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

const Remote = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [lastname, setLastName] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.lastname = ""; errors.massage = "";

            var url = "https://wa.me/7410153100?text="
                + " Name : " + name + " Email : " + email + " Phone : " + number + " Last Name : " + lastname + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setLastName("");
        setMassage("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!lastname) {
            errors.lastname = "Last Name Is Requried";
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Comments Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services4.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Remote Maintenance & Network Monitoring
                                </h2>
                                <p>
                                    Have you ever been stuck in a technological bind caused by
                                    a flaw in your business’s network security? Organizations
                                    who aren’t prepared for the constant upkeep that a computing
                                    network demands will quickly find themselves overwhelmed. Despite
                                    the difficulties that come with technology management, maintenance
                                    is an absolutely vital task that must be fulfilled in order to get
                                    the most from your hardware and software. ITSC's proactive Remote
                                    Monitoring and Maintenance solution is designed to remediate these
                                    issues for small and medium-sized businesses,
                                    and make them more affordable than ever before.
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Comprehensive Remote Monitoring </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Regular System Updates and Patches </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Affordable Fixed Rates </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Real-Time Expertise Just a Phone Call Away </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Mitigate Risk With Remote Support </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> 24/7 Help Desk </b></span>
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Comprehensive Remote Monitoring
                                </h2>
                                <h3>
                                    Mitigate issues before they become problems.
                                </h3>
                                <p>
                                    Many businesses tend to only fix their technology when there’s something
                                    wrong with it. This is called the break-fix IT model, and compared to
                                    ITSC's proactive IT model, it’s incredibly inefficient.
                                    When your technology is continuously being broken and fixed again, the resulting costs can be
                                    devastating; especially if they aren’t called for in your IT budget.
                                </p>
                                <p>
                                    ITSC's remote monitoring solution is designed to detect
                                    potential issues in your organization’s infrastructure. This helps us
                                    repair and resolve the issue before it causes a budget-breaking problem.
                                    Furthermore, we can prevent most instances of downtime by actively monitoring
                                    your network’s activity. The second a problem shows itself,
                                    we’re on it, fixing the problem. In fact,
                                    these issues are often resolved before you even know they exist.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Regular System Updates and Patches
                                </h2>
                                <h3>
                                    Ensure your systems are always up to date.
                                </h3>
                                <p>
                                    Your users take advantage of several different kinds of software
                                    and applications that are critical to the day-to-day operations
                                    of your business. Updating all of these periodically can seem like
                                    a chore, due to the seemingly random nature of patch releases and
                                    version updates. Remembering to update all of these on your own can
                                    be challenging and tedious, not to mention time-consuming. Furthermore,
                                    these patches and updates usually fix critical security flaws that need to be
                                    remediated in order to maintain optimal network security.
                                </p>
                                <p>
                                    Instead of letting your team waste precious moments updating and installing
                                    software patches, ITSC can do it all remotely.
                                    You’ll never have to worry about missing a critical software update again.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Affordable Fixed Rates
                                </h2>
                                <h3>
                                    Don’t let technology break your budget.
                                </h3>
                                <p>
                                    Too often, technology issues can break your business’ budget.
                                    This is primarily if your business isn’t anticipating issues,
                                    like hardware failure. These problems can be extremely costly,
                                    even though they can easily be prevented with proper upkeep and maintenance.
                                    The only issue is that many small and medium-sized businesses have difficulty justifying
                                    the expenses that an internal IT department brings.
                                </p>
                                <p>
                                    This is why ITSC offers a competitive fixed rate.
                                    We provide the proper upkeep and maintenance of your mission-critical
                                    systems, functioning just like your internal IT department, without
                                    the costly salaries. Even if your organization does have an internal
                                    IT department, we can work alongside them to perform the daily maintenance
                                    that they might not have time for. This helps them better
                                    innovate and provide new solutions for your business model.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Services
                                </h2>
                                <NavLink className="text-decoration-none" to='/itconsulting'>
                                    <p>
                                        IT Consulting
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/manageditservices'>
                                    <p>
                                        Managed Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/comprehensive'>
                                    <p>
                                        Comprehensive IT Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/remote'>
                                    <p>
                                        Remote Monitoring & Maintenance
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/itvendor'>
                                    <p>
                                        IT Vendor Management
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/infrastructure'>
                                    <p>
                                        Infrastructure Support Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/professional'>
                                    <p>
                                        Professional Server Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/staffingservices'>
                                    <p>
                                        Staffing Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/helpdesk'>
                                    <p>
                                        24/7 Help Desk
                                    </p>
                                </NavLink>
                            </div>

                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Questions?
                                </h2>
                                <p>
                                    Do you have questions about IT Services and what they can do for your company? Fill out this simple form with your questions and our team respond promptly!
                                </p>
                                <p>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-12 ">
                                            <input type="text" placeholder='Name' className="form-control text-dark" style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Last Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setLastName(e.target.value)} value={lastname}
                                            />
                                            {errors.lastname && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.lastname}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="email" placeholder='Email' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Phone' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className="col-lg-12">
                                            <textarea type="text" placeholder='Comments' className="form-control" style={{ borderRadius: "2px", height: "100px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button type="submit" className="  FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Conatct Us </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Remote