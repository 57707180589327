import React, { useRef, useState } from 'react'
import "./ContactUs.css"
import { NavLink } from 'react-router-dom'

const ContactUs = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [company, setCompany] = useState("");
    const [lastname, setLastName] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.lastname = ""; errors.email = ""; errors.company = ""; errors.number = ""; errors.massage = "";

            var url = "https://wa.me/7410153100?text="
                + " Name : " + name +
                " Last Name : " + lastname + " Email : " + email + " Phone : " + number + " Company Name : " + company + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setCompany("");
        setLastName("");
        setMassage("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!lastname) {
            errors.lastname = "Last Name Is Requried";
        }

        if (!company) {
            errors.company = "Company Name Is Requried";
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Comments Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <div className="container-fluid p-0 ">
                <div className='' style={{ lineHeight: "0px" }}>
                    <iframe className='w-100 m-0' title="gmap" style={{ height: "370px" }} src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3783.393402872083!2d73.77798067465191!3d18.511116169464106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDMwJzQwLjAiTiA3M8KwNDYnNTAuMCJF!5e0!3m2!1sen!2sin!4v1718607144823!5m2!1sen!2sin">
                    </iframe>
                </div>
            </div>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 ITServicesCommon d-flex justify-content-center mb-3">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <h2 className=''>
                                    Contact Us for Services or Support
                                </h2>
                                <p className='mt-4'>
                                    <strong>Information Technology Support Center Pvt.Ltd</strong> <br />
                                    FLAT NO 20 528, NARAYAN PETH, PUNE 411030, <br />
                                </p>
                                <p>
                                    41/4, DHANSHRI SOCIETY, NAVSAHYADRI SOCIETY, <br />
                                    Karve Nagar, Pune, Maharashtra 411052
                                </p>
                                <p>
                                    Call us today <br />
                                    <NavLink className="text-decoration-none" to='tel:917410153100'>
                                        <span className='' style={{ color: "#01548d", fontWeight: "500" }}>+ 91 7410153100</span>
                                    </NavLink>
                                </p>
                                <p>
                                    <strong className='pb-2 pt-2'>Contact us with the form below!</strong>
                                </p>
                                <p>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display g-lg-4 pt-2" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-6 ">
                                            <input type="text" placeholder='Name' className="form-control text-dark" style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>
                                        &nbsp;

                                        <div className='col-lg-6'>
                                            <input type="text" placeholder='Last Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setLastName(e.target.value)} value={lastname}
                                            />
                                            {errors.lastname && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.lastname}</div>}
                                        </div>
                                        &nbsp;

                                        <div className='col-lg-6'>
                                            <input type="email" placeholder='Email' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>
                                        &nbsp;

                                        <div className='col-lg-6'>
                                            <input type="text" placeholder='Company Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setCompany(e.target.value)} value={company}
                                            />
                                            {errors.company && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.company}</div>}
                                        </div>
                                        &nbsp;

                                        <div className='col-lg-6'>
                                            <input type="text" placeholder='Phone' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>
                                        &nbsp;

                                        <div className="col-lg-6">
                                            <textarea type="text" placeholder='Comments' className="form-control" style={{ borderRadius: "2px", height: "100px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button type="submit" className="  FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Conatct Us </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Services
                                </h2>
                                <p>
                                    <div class="accordion" id="accordionPanelsStayOpenExample">
                                        <div class="accordion-item">
                                            <h3 class="accordion-header" id="panelsStayOpen-headingOne">
                                                <button class="accordion-button" style={{ fontWeight: "500" }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                    Phone Support
                                                </button>
                                            </h3>
                                            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                                <div class="accordion-body">
                                                    You're human, and your support should be too. Speak to a support team member on the phone now!
                                                    <p className='pt-2 mt-lg-2'>
                                                        <strong>Phone:</strong>
                                                        <NavLink className="text-decoration-none" to='tel:917410153100'>
                                                            <span className='' style={{ color: "#01548d", fontWeight: "500" }}> 7410153100</span>
                                                        </NavLink>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h3 class="accordion-header" id="panelsStayOpen-headingTwo">
                                                <button class="accordion-button collapsed" style={{ fontWeight: "500" }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                    Ticket By Email
                                                </button>
                                            </h3>
                                            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                                <div class="accordion-body">
                                                    Send an email to the Help Desk to create a ticket automatically and communicate with our team.
                                                    <NavLink className='text-decoration-none' to='mailto:support@itsc.co.in'>
                                                        <div className='d-flex justify-content-center pt-2'>
                                                            <button className=" w-100 FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Submit A Ticket </b></button>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h3 class="accordion-header" id="panelsStayOpen-headingThree">
                                                <button class="accordion-button collapsed" style={{ fontWeight: "500" }} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                    Ticket By Portal
                                                </button>
                                            </h3>
                                            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                                <div class="accordion-body">
                                                    Create and manage tickets via our secure online Help Desk Portal.
                                                    <NavLink className='text-decoration-none' to='mailto:hr@itsc.co.in'>
                                                        <div className='d-flex justify-content-center pt-2'>
                                                            <button className=" w-100 FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Support Portal </b></button>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </div>

                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Services
                                </h2>
                                <NavLink className="text-decoration-none" to='/manageditservices'>
                                    <p>
                                        Managed Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/itconsulting'>
                                    <p>
                                        IT Consulting
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/comprehensive'>
                                    <p>
                                        Comprehensive IT Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/remote'>
                                    <p>
                                        Remote Monitoring & Maintenance
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/itvendor'>
                                    <p>
                                        IT Vendor Management
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/helpdesk'>
                                    <p>
                                        24/7 Help Desk
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/infrastructure'>
                                    <p>
                                        Infrastructure Support Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/professional'>
                                    <p>
                                        Professional Server Support
                                    </p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ContactUs