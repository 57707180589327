import React, { useRef, useState } from 'react'
import "./ManagedITServices.css"
import { NavLink } from 'react-router-dom';

const ManagedITServices = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [lastname, setLastName] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.lastname = ""; errors.massage = "";

            var url = "https://wa.me/7410153100?text="
                + " Name : " + name + " Email : " + email + " Phone : " + number + " Last Name : " + lastname + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setLastName("");
        setMassage("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!lastname) {
            errors.lastname = "Last Name Is Requried";
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Comments Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services1.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Managed IT Services for MSME
                                </h2>
                                <h3>
                                    ITSC managed IT services can help eliminate your company's technology-based headaches.
                                </h3>
                                <p>
                                    Do you depend on technology to maximize the efficiency of
                                    your business? Has that technology ever let you down? If you
                                    are like other businesses, you have your fair share of horror
                                    stories. Whether you have tales of downtime brought on by computer
                                    or network problems, or simply don’t like how much your organization
                                    is paying to keep your IT up and running, Coleman Technologies is here
                                    to produce results. Our flat-rate service plan will deliver your company
                                    enterprise-level IT support and solutions that are tailor-made to relieve all
                                    of your organization’s technology-induced pain points.
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Reduce Costly Downtime </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Dependable Service </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Budget Your IT </b></span>
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Benefits of Managed IT Services
                                </h2>
                                <h3>
                                    Cost Predictability:
                                </h3>
                                <p>
                                    Fixed monthly fees for managed services make budgeting easier and more predictable.
                                </p>
                                <h3>
                                    Expertise and Specialization:
                                </h3>
                                <p>
                                    Access to a team of IT experts with specialized knowledge and experience.
                                </p>
                                <h3>
                                    Improved Security:
                                </h3>
                                <p>
                                    Enhanced security measures and proactive monitoring to protect against threats.
                                </p>
                                <h3>
                                    Increased Efficiency:
                                </h3>
                                <p>
                                    Streamlined IT operations and reduced downtime through proactive management and support.
                                </p>
                                <h3>
                                    Focus on Core Business:
                                </h3>
                                <p>
                                    Allows organizations to focus on their core activities and strategic goals without being bogged down by IT issues.
                                </p>
                                <h3>
                                    Scalability:
                                </h3>
                                <p>
                                    Easily scale IT resources and services up or down based on business needs.
                                </p>
                                <h3>
                                    Enhanced Performance:
                                </h3>
                                <p>
                                    Optimized IT infrastructure and systems for better performance and reliability.
                                </p>
                                <h3>
                                    Access to Latest Technology:
                                </h3>
                                <p>
                                    Leverage up-to-date technologies and tools without the need for large capital investments.
                                </p>
                                <h3>
                                    Proactive Management:
                                </h3>
                                <p>
                                    Anticipate and address potential issues before they become major problems.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Services
                                </h2>
                                <NavLink className="text-decoration-none" to='/itconsulting'>
                                    <p>
                                        IT Consulting
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/manageditservices'>
                                    <p>
                                        Managed Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/comprehensive'>
                                    <p>
                                        Comprehensive IT Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/remote'>
                                    <p>
                                        Remote Monitoring & Maintenance
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/itvendor'>
                                    <p>
                                        IT Vendor Management
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/infrastructure'>
                                    <p>
                                        Infrastructure Support Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/professional'>
                                    <p>
                                        Professional Server Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/staffingservices'>
                                    <p>
                                        Staffing Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/helpdesk'>
                                    <p>
                                        24/7 Help Desk
                                    </p>
                                </NavLink>
                            </div>

                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Questions?
                                </h2>
                                <p>
                                    Do you have questions about IT Services and what they can do for your company? Fill out this simple form with your questions and our team respond promptly!
                                </p>
                                <p>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-12 ">
                                            <input type="text" placeholder='Name' className="form-control text-dark" style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Last Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setLastName(e.target.value)} value={lastname}
                                            />
                                            {errors.lastname && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.lastname}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="email" placeholder='Email' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Phone' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className="col-lg-12">
                                            <textarea type="text" placeholder='Comments' className="form-control" style={{ borderRadius: "2px", height: "100px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button type="submit" className="  FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Conatct Us </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ManagedITServices