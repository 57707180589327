import React, { useRef, useState } from 'react'
import "./Footer.css"
import { NavLink } from 'react-router-dom';

const Footer = () => {

    const form = useRef();

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.username = ""; errors.password = "";

            var url = "https://wa.me/7410153100?text="
                + " UserName : " + username + " Password : " + password;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setUserName("");
        setPassword("");

    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)

        if (!username) {
            errors.username = "User Name Is Requried";
        }
        else if (username.length < 4) {
            errors.username = "User Name Requried At Least Four Characters"
        }

        if (!password) {
            errors.password = "Password Is Requried";
        }
        else if (password.length < 10) {
            errors.password = "Password Is Requried "
        }

        return errors;

    }

    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#171717" }}>
                    <div className="container-lg Saira FooterMainSize">
                        <div className="row g-4 BottomBorder" >
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div>
                                    <div className='FooterCommonHeading'>
                                        <h2>
                                            Customer Login
                                        </h2>
                                    </div>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-12 ">
                                            <div className='d-flex align-items-center Saira' style={{ borderRadius: "4px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", height: "50px" }}>
                                                <div className='ps-2'>
                                                    <i class="fa-solid fa-user" style={{ color: "#005792", fontSize: "18px" }}></i>
                                                </div>
                                                <input type="text" placeholder='Username' className="form-control text-dark" style={{ border: "none" }} id="inputName" name="name"
                                                    onChange={e => setUserName(e.target.value)} value={username}
                                                />
                                            </div>
                                            {errors.username && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.username}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <div className='d-flex align-items-center Saira' style={{ borderRadius: "4px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", height: "50px" }}>
                                                <div className='ps-2'>
                                                    <i class="fa-solid fa-lock" style={{ color: "#005792", fontSize: "18px" }}></i>
                                                </div>
                                                <input type="password" placeholder='Password' className="form-control " style={{ border: "none" }} id="inputEmail4" name="email"
                                                    onChange={e => setPassword(e.target.value)} value={password}
                                                />
                                            </div>
                                            {errors.password && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.password}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button type="submit" className="  FooterLoginButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Login </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div>
                                    <div className='FooterCommonHeading'>
                                        <h2>
                                            News & Updates
                                        </h2>

                                        <h6>
                                            ITSC Wins Second Consecutive Consumer Choice Award
                                        </h6>

                                        <p>
                                            When it comes to growth and advancement, small and medium-sized businesses (SMBs) in British Columbia have been effectively leveraging ITSC’ affordable enterprise-level IT practices and solutions since 1999. The proof: Coleman Technol...
                                        </p>

                                        <a className='text-decoration-none' href="/about">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button className="  FooterReadMoreButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Read More </b></button>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div>
                                    <div className='FooterCommonHeading'>
                                        <h2>
                                            Contact us
                                        </h2>
                                        <p>
                                            Learn more about what ITSC can do for your business.
                                        </p>
                                        <p>

                                            Information Technology Support Center Pvt.Ltd <br />
                                            FLAT NO 20 528, NARAYAN PETH, PUNE 411030,  <br />
                                        </p>
                                        <p>
                                            41/4, DHANSHRI SOCIETY, NAVSAHYADRI SOCIETY,<br />
                                            Karve Nagar, Pune, Maharashtra 411052
                                        </p>

                                        <div className='SocialIconFooter'>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-facebook-f m-0"></i></NavLink>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-lg-flex text-center p-lg-3 mt-4 justify-content-lg-between align-items-center BottomTop" >
                            <div className='text-white mt-2' style={{ fontSize: "14px" }} >
                                Copyright 2024 ITSC. All Rights Reserved.
                            </div>
                            <div className='mt-2 text-white ' style={{ fontSize: '10px', fontStyle: "italic" }} >
                                Design and Develop by <a href="https://icloudsoftware.co.in" className="text-white text-decoration-none " style={{ fontSize: "11px", cursor: "pointer", fontWeight: "500" }} >@ icloudsoft</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer