import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom';

const Infrastructure = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [lastname, setLastName] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.lastname = ""; errors.massage = "";

            var url = "https://wa.me/7410153100?text="
                + " Name : " + name + " Email : " + email + " Phone : " + number + " Last Name : " + lastname + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setLastName("");
        setMassage("");

    }

    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!lastname) {
            errors.lastname = "Last Name Is Requried";
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Comments Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row g-4 mb-4 ITServicesCommon d-flex justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className='ManagedItServices'>
                                <p>
                                    <img className='FloatImg' src={require("../Img/managed-services10.png")} alt="icloudsoft_logo" />
                                </p>
                                <h2 className=''>
                                    Infrastructure Support Services
                                </h2>
                                <p>
                                    Organizational difficulties that businesses have with
                                    their IT infrastructure often leave them with difficulties
                                    and deficits that hinder overall operational effectiveness.
                                    For this reason companies need to have a strategy in place
                                    when setting up their business’ computing infrastructure.
                                    When searching for the right company to help you set up and
                                    manage your IT infrastructure, you have to take into account
                                    the company’s competence in keeping your office organized.
                                    At ITSC, our services promote the implementation and management of
                                    a coordinated, efficient, and dynamic computing infrastructure
                                    that is designed to take your company where you want it to go.
                                    Our infrastructure strategy is made up of four crucial variables that
                                    are designed to provide organizations with a clean and dynamic computing infrastructure.
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Server Room Design </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Server Rack Design </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Battery Backup </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Cable Management </b></span>
                                </p>
                                <p>
                                    <i class="fa-solid fa-star"></i>  <span className='ms-1'><b> Infrastructure Support Services </b></span>
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Server Room Design
                                </h2>
                                <h3>
                                    New technologies have radically changed the modern server room.
                                </h3>
                                <p>
                                    In order to protect your central computing infrastructure, you will likely
                                    need to make some alterations to the property in which you plan on storing
                                    your servers. Our technicians understand the essentials of creating the
                                    perfect server room to meet your organization's needs.
                                    Since our technicians are versed in industry best practices, and have established relationships with contractors and vendors,
                                    we have the resources to produce the desired result no matter your needs.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Server Rack Design
                                </h2>
                                <h3>
                                    Customized solutions to ensure that all of your servers are secure and reliable.
                                </h3>
                                <p>
                                    Every organization’s needs are different. If your organization
                                    needs to house several servers, you will need a system to manage
                                    your infrastructure. At ITSC, our technicians
                                    understand that your rack space can help you coordinate your computing
                                    resources, as well as provide a streamlined effect that will provide technicians
                                    a valuable sitemap when doing maintenance on those systems.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Battery Backup
                                </h2>
                                <h3>
                                    Complete battery backup that works to protect your infrastructure.
                                </h3>
                                <p>
                                    Events that are beyond your control can be devastating to your
                                    computing infrastructure if you aren’t prepared for them. A common event,
                                    such as a lightning strike, can fry your server, leaving your organization
                                    in shambles. To protect crucial hardware, we can provide your organization
                                    with Uninterrupted Power Supply devices that protect your crucial
                                    hardware from power surges that could result in catastrophe.
                                </p>
                            </div>

                            <div className='ManagedItServices'>
                                <h2>
                                    Cable Management
                                </h2>
                                <h3>
                                    Comprehensive cabling and wiring strategies to build a safe and efficient office.
                                </h3>
                                <p>
                                    Unfortunately, cables and wires are a must for any business looking
                                    to employ a ubiquitous computing infrastructure. The key is to avoid
                                    the dreaded mess of cables that could not only cause issues getting
                                    around your establishment’s computing infrastructure, but could create
                                    problems when adding and subtracting devices from your infrastructure.
                                    Our technicians are trained with the best practices
                                    to run cables proficiently with long term operations in mind.
                                </p>
                                <p>
                                    ITSC technicians know that your organization depends
                                    heavily on your technology and understands how to keep the whole system
                                    efficient to support regular maintenance and to cut expenses that come
                                    from inefficiently-run IT. To learn more about our comprehensive
                                    infrastructure support services, contact us today at
                                    <NavLink className="text-decoration-none" to='tel:917410153100'>
                                        <span className='ms-2 me-1' style={{ color: "#01548d", fontWeight: "500" }}>+ 91 7410153100</span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-10">
                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Services
                                </h2>
                                <NavLink className="text-decoration-none" to='/itconsulting'>
                                    <p>
                                        IT Consulting
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/manageditservices'>
                                    <p>
                                        Managed Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/comprehensive'>
                                    <p>
                                        Comprehensive IT Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/remote'>
                                    <p>
                                        Remote Monitoring & Maintenance
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/itvendor'>
                                    <p>
                                        IT Vendor Management
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/infrastructure'>
                                    <p>
                                        Infrastructure Support Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/professional'>
                                    <p>
                                        Professional Server Support
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/staffingservices'>
                                    <p>
                                        Staffing Services
                                    </p>
                                </NavLink>
                                <NavLink className="text-decoration-none" to='/helpdesk'>
                                    <p>
                                        24/7 Help Desk
                                    </p>
                                </NavLink>
                            </div>

                            <div className='ManagedITServicesMainServiceses '>
                                <h2>
                                    Managed IT Questions?
                                </h2>
                                <p>
                                    Do you have questions about IT Services and what they can do for your company? Fill out this simple form with your questions and our team respond promptly!
                                </p>
                                <p>
                                    <form ref={form} onSubmit={loginHandle} className="row playfair-display g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-12 ">
                                            <input type="text" placeholder='Name' className="form-control text-dark" style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Last Name' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setLastName(e.target.value)} value={lastname}
                                            />
                                            {errors.lastname && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.lastname}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="email" placeholder='Email' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-12'>
                                            <input type="text" placeholder='Phone' className="form-control " style={{ borderRadius: "2px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "50px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className="col-lg-12">
                                            <textarea type="text" placeholder='Comments' className="form-control" style={{ borderRadius: "2px", height: "100px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="">
                                            <div className='d-flex justify-content-start mb-3'>
                                                <button type="submit" className="  FirstButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Conatct Us </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Infrastructure