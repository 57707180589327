import React from 'react'

const NonProfit = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-lg p-lg-3">
                    <div className="row p-3">
                        <div className='IndustriesMain'>
                            <h2>
                                Our Expertise
                            </h2>
                            <h5>
                                Expertise in Non-Profit
                            </h5>
                            <p>
                                <i>
                                    Digital transformation aimed at delivering enhanced customer experience enabled by big data and analytical insights.
                                </i>
                            </p>
                            <p>
                                ITSC delivers a seamless insurance sales and service journey for our
                                clients by leveraging robust and elastic IT infrastructure
                                (application and Infra) and automation (Cognitive and Robotic).
                            </p>
                            <p>
                                With our breadth of experience serving global insurance and re-insurance
                                companies in sales and distribution, new business and underwriting,
                                policy administration, claims, billing, accounting, risk and compliance,
                                brokerage and third party administration, ITSC offers you a robust and
                                comprehensive service portfolio,
                                product offerings, and deep expertise in design and engineering
                            </p>
                            <p>
                                We leverage a set of innovative technology platforms such as Cognitive
                                Systems, Robotics, Human Machine Interface, Smart Connected Devices,
                                Next Gen App Architectures and Software Defined Everything to deliver
                                digitally-enabled business processes and domain-centric solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#e8e8e8" }}>
                    <div className="container-lg  CommanIndustriesStories">
                        <h2 className='CommanIndustries'>
                            What We Think
                        </h2>
                        <div className="row  g-4">
                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Insurance1.png")} alt="" />
                                            <div className='content'>
                                                <h2> Claims Transformation in Insurance </h2>
                                                <p>
                                                    While feature-rich and highly functional, the legacy
                                                    platforms that hold together most insurance claims workflows have...
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Insurance2.png")} alt="" />
                                            <div className='content'>
                                                <h2> Designing a commercial model to unlock revenue potential </h2>
                                                <p>
                                                    With matured outsourcing and the rise in customers’ expectations,
                                                    the service providers are moving towards emerging...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Insurance3.png")} alt="" />
                                            <div className='content'>
                                                <h2> Data Monetization: The Next Frontier for Insurance Firms </h2>
                                                <p>
                                                    Traditional insurance carriers are sitting on a mountain of data.
                                                    To extract maximum value from this data, they need to...
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/Insurance4.png")} alt="" />
                                            <div className='content'>
                                                <h2> How Pod-Based Engineering Can Transform Process and Product </h2>
                                                <p>
                                                    Insurance companies need to respond quickly to market shifts.
                                                    Pod-based engineering is a nimble solution.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default NonProfit