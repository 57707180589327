import React from 'react'
import "./About.css"

const About = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0 ' style={{ backgroundImage: `url(${require("../Img/AboutBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg CommanPadding">
                        <div className="row g-4">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutServices'>
                                    <h2>
                                        About Information Technology Support Center
                                    </h2>
                                    <p>
                                        At Information Technology Support Center, we're passionate
                                        about leveraging technology to empower businesses and organizations
                                        to thrive in the digital age. With a commitment to excellence and
                                        innovation, we deliver comprehensive IT services and
                                        solutions tailored to meet the diverse needs of our clients.
                                    </p>

                                    <a className='text-decoration-none' href="/services">
                                        <div className='d-flex justify-content-start'>
                                            <button className="AboutOurDiffButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Our Services </b></button>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutServicesImg'>
                                    <img className='w-100' src={require("../Img/AboutPage1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/AboutSevicesPlanBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%' }}>
                    <div className='' style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
                        <div className="container-lg CommanPadding " >
                            <div className='ServicesPlanAbout'>
                                <h3>
                                    Our Managed Services Plan
                                </h3>
                                <div className='d-flex justify-content-center'>
                                    <p>
                                        We partner with many types of businesses in the area, and strive to eliminate IT issues before they cause expensive downtime, so you can continue to drive your business forward.
                                    </p>
                                </div>
                            </div>
                            <div className="row g-4 p-2">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className='MainServicesesAbout'>
                                        <i class="fa-solid fa-laptop"></i>
                                        <h2>
                                            Proactive & Smart Technology
                                        </h2>
                                        <p>
                                            Dedication to the prevention of data loss
                                            and other business technology disasters.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className='MainServicesesAbout'>
                                        <i class="fa-solid fa-clock"></i>
                                        <h2>
                                            Round the Clock Maintenance
                                        </h2>
                                        <p>
                                            24/7 access to remote & on-site server
                                            remediation services at a flat monthly rate.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className='MainServicesesAbout'>
                                        <i class="fa-solid fa-headphones"></i>
                                        <h2>
                                            Help Desk & Remote Computer Support
                                        </h2>
                                        <p>
                                            Fast and reliable IT solutions and
                                            maintenance provided for your business users.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%' }}>
                    <div className="container-lg CommanPadding " >
                        <div className='ServicesPlanAbout'>
                            <h3>
                                Our Managed Services Plan
                            </h3>
                            <div className='d-flex justify-content-center'>
                                <p>
                                    We partner with many types of businesses in the area, and strive to eliminate IT issues before they cause expensive downtime, so you can continue to drive your business forward.
                                </p>
                            </div>
                        </div>
                        <div className="row g-4 p-2">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='MainServicesesAbout'>
                                    <i class="fa-solid fa-laptop"></i>
                                    <h2>
                                        Proactive & Smart Technology
                                    </h2>
                                    <p>
                                        Dedication to the prevention of data loss
                                        and other business technology disasters.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='MainServicesesAbout'>
                                    <i class="fa-solid fa-clock"></i>
                                    <h2>
                                        Round the Clock Maintenance
                                    </h2>
                                    <p>
                                        24/7 access to remote & on-site server
                                        remediation services at a flat monthly rate.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className='MainServicesesAbout'>
                                    <i class="fa-solid fa-headphones"></i>
                                    <h2>
                                        Help Desk & Remote Computer Support
                                    </h2>
                                    <p>
                                        Fast and reliable IT solutions and
                                        maintenance provided for your business users.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg CommanPadding">
                        <div className="row g-4">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutServicesImg'>
                                    <img className='w-100' src={require("../Img/AboutPage2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className='AboutServices'>
                                    <h2>
                                        The IT Industry Secret
                                    </h2>
                                    <p>
                                        We do things differently. Stop paying outrageously
                                        high hourly rates that can sneak their way into your bill,
                                        and get proactive, giving everyone in your company the
                                        support they need, when they need it, without the huge costs.
                                    </p>
                                    <h6>
                                        We call it ITSC, and it’s
                                        designed to give you and your entire organization
                                        peace of mind when it comes to your IT.Get the IT Secret
                                    </h6>

                                    <a className='text-decoration-none' href="/manufacturing">
                                        <div className='d-flex justify-content-start'>
                                            <button className="AboutOurDiffButton"  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Get the IT Secret </b></button>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="container-fluid" style={{ backgroundColor: "#005792" }}>
                    <div className="container-lg FeatureMain">
                        <div className="row g-4 d-flex justify-content-center align-items-center MainText">
                            <div className="col-xl-9 col-md-9 ">
                                <div className='RecentPostTop'>
                                    <h4>
                                        Let’s Get Together And Talk About Your Technology
                                    </h4>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-3">
                                <div className='RecentPostTop text-lg-center'>
                                    <button>
                                        REQUEST CONSULTATION
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#005792" }}>
                    <div className="container-lg FeatureMain">
                        <div className="row g-4 d-flex justify-content-center align-items-center MainText">
                            <div className="col-xl-10 col-md-9 ">
                                <div className='RecentPostTop'>
                                    <h4>
                                        Meet the People Responsible for Managing Your Business IT
                                    </h4>
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-3">
                                <div className='RecentPostTop text-lg-center'>
                                    <button>
                                        Meet The Team
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="container-lg  FeatureMain">
                        <div className='ServicesHome'>
                            <h3>
                                Our Mission and Vission
                            </h3>
                        </div>

                        <div className="row d-flex justify-content-around mt-3 g-4 ">
                            <div className="col-xl-5 col-lg-5 col-md-5 ">
                                <div>
                                    <div className=''>
                                        <img className='w-100' src={require("../Img/mission.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <div className='RecentPost-Content-About'>
                                        <h2>
                                            Mission
                                        </h2>
                                        <p>
                                            Our mission is to deliver exceptional IT services and solutions
                                            that enable our clients to thrive in the digital age. Through
                                            strategic partnerships, expert guidance, and cutting-edge technology,
                                            we aim to streamline operations, enhance productivity, and drive
                                            sustainable growth for businesses of all sizes. We are committed
                                            to delivering superior customer service, fostering long-term relationships
                                            built on trust and integrity, and continuously evolving to meet the
                                            ever-changing needs of our clients. At the heart of everything we do
                                            is a passion for innovation and a dedication to
                                            exceeding expectations, empowering our clients
                                            to succeed in a rapidly evolving technological landscape.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-5 col-lg-5 col-md-5">
                                <div>
                                    <div className=''>
                                        <img className='w-100' src={require("../Img/Vision.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <div className='RecentPost-Content-About'>
                                        <h2>
                                            Vision
                                        </h2>
                                        <p>
                                            Our vision is to be the forefront partner in empowering
                                            businesses and individuals through innovative IT services
                                            and solutions. We strive to create a seamless digital ecosystem
                                            where technology enhances productivity, fosters growth,
                                            and enriches lives. By delivering cutting-edge solutions
                                            tailored to our clients' unique needs, we aim to redefine
                                            industry standards and drive positive change in the global
                                            marketplace. Our commitment to excellence, integrity,
                                            and customer satisfaction guides us as
                                            we shape the future of IT, one solution at a time.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                <div className=''>
                                    <div className='BlogCommonImg'>
                                        <img className='' src={require("../Img/BlogPost2.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <div className='RecentPost-Content-About'>
                                        <h2>
                                        Values
                                        </h2>
                                        <p>
                                            Our IT Service Desk values are taken from the Departmental values.
                                            To deliver a valued customer experience we will:
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About